<template>
  <div v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中"
  element-loading-background="rgba(255, 255, 255, 0.5)">
    <ShopsAndSites
    v-if="tress.length!=0"
        placeholder="请选择店铺"
        style="margin-right:10px;"
        :list='tress'
        @getShopId='getID'>
    </ShopsAndSites>
    <el-select v-model="pageInfo.type" clearable style="width：150px;margin-right:10px" placeholder="请选择同步类型" @change="find">
        <el-option
        :label="item.value" 
        :value="item.id"
        v-for="item in typeItemList"
        :key="item.id"
        ></el-option>
    </el-select>
    <el-select v-model="pageInfo.status" clearable style="width：150px;margin-right:10px" placeholder="请选择同步状态" @change="find">
        <el-option
        :label="item.value" 
        :value="item.id"
        v-for="item in statusItemList"
        :key="item.id"
        ></el-option>
    </el-select>
    <el-button type="primary" icon="el-icon-search" @click="find">查询</el-button>
    <el-table
    border
    :data="tableData"
    style="width: 100%;margin-top:20px"
    cell-style="text-align:center"
    header-cell-style="text-align:center;background:#EFEFEF">
        <el-table-column
            prop="typeName"
            label="同步类型"
            width="width">
        </el-table-column>
        <el-table-column
            label="同步状态"
            width="width">
            <template slot-scope="scope">
                <span v-if='scope.row.status==267' style="color:#999999">{{scope.row.statusName}}</span>
                <span v-if='scope.row.status==270' style="color:#51c9a8">{{scope.row.statusName}}</span>
                <span v-if='scope.row.status==269' style="color:#F1453d">{{scope.row.statusName}}</span>
                <span v-if='scope.row.status==268' style="color:#5E8cE2">{{scope.row.statusName}}</span>
                <span v-if='scope.row.status==271' style="color:red">{{scope.row.statusName}}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="任务创建时间"
            width="width">
        </el-table-column>
        <el-table-column
            prop="executeTime"
            label="任务执行时间"
            width="width">
        </el-table-column>
        <el-table-column
            prop="tips"
            label="备注"
            width="width">
        </el-table-column>
    </el-table>
    <div style="text-align:center;padding:20px">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  listPage, 
  pageInit
} from '@/api/basicManage/dataSync.js'
import ShopsAndSites from '@/components/ShopsAndSites.vue'
export default {
    components:{
        ShopsAndSites
    },
    data(){
      return {
        loading:false,
        statusItemList:[],
        typeItemList:[],
        tableData:[],
        pageInfo:{
         current:'',
         pageSize:'',
         shopId:'',
         marketPlaceId:'',
         type:'',
         status:'',
        },
        shopId:'',
        total:'',
        tress:[]
      };
    },
    mounted(){
        this.getPageInit()
    },
    methods:{
        getID(shopID,marketPlaceId){
            this.pageInfo.shopId=shopID
            this.pageInfo.marketPlaceId=marketPlaceId
            this.getListPage()
        },
        find(){
            this.pageInfo.current=1
            this.getListPage()
        },
        handleSizeChange(val){
            this.pageInfo.pageSize=val
            this.getListPage()
        },
        handleCurrentChange(val){
            this.pageInfo.current=val
            this.getListPage()
        },
        getListPage(){
            this.loading=true
            console.log(this.pageInfo);
            listPage(this.pageInfo).then(res=>{
                console.log(res);
                this.tableData=res.data.values
                this.total=res.data.pageInfo.total
                this.loading=false
            })
        },
        getPageInit(){
            pageInit().then(res=>{
                console.log(res.data.data.tress);
                this.typeItemList=res.data.data.typeItemList
                this.statusItemList=res.data.data.statusItemList
                this.tress=res.data.data.tress
            })
        },
        clear(){
            this.pageInfo.shopId=''
            this.pageInfo.marketPlaceId=''
        }
    }
}
</script>

<style>

</style>